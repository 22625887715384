import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import CustomLink from '../components/CustomLink.js'
import ColumnAlternatingButton from '../components/columnAlternatingButton'
import ColumnAlternatingYouTube from '../components/columnAlternatingYouTube'
import PerformanceFocus from '../components/performanceFocus'
import Card from '../components/card'
import CardIconsLink from '../components/cardIconsLink.js'
import TechPartnerPlatforms from '../components/techPartnerPlatforms'
import Cta from '../components/cta'
import Showdown from 'showdown'

const TechnologyPartnersTemplate = ({ data }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol lg="12">
              <div className="mt-3 richtext divlink tp-overview" dangerouslySetInnerHTML={{ __html: post.html }} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>


      {post.frontmatter.podcasts && 
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.podcasts.title}
              </h2>

              {post.frontmatter.podcasts.subtitle &&
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {post.frontmatter.podcasts.title}
                </h3>
              }

              {post.frontmatter.podcasts.description &&
                <div
                  className="mt-3 richtext divlink tp-overview"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.podcasts.description) }}
                />
              }

              {post.frontmatter.podcasts.podcast && (
                <MDBRow className="pt-5">
                  {post.frontmatter.podcasts.podcast.map((podcast, index) => (
                    <Card
                      key={index}
                      collg="4"
                      colmd="6"
                      height="7.5rem"
                      title={podcast.title}
                      subtitle={podcast.subtitle}
                      description={podcast.linktext}
                      image={podcast.image.childImageSharp.gatsbyImageData}
                      alt={podcast.alttext}
                      placement="card"
                      link={ podcast.link ? podcast.link : null }
                      descriptionClass="text-card-small"
                    />
                  ))}
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      }

      {post.frontmatter.video && (
        <section className={post.frontmatter.podcasts ? "bg-white" : "bg-white-grey-gradient"}>
          <FadeInWhenVisible>
            <MDBContainer>
              <div className="about-overview">
                {post.frontmatter.video.map((videos, index) => (
                  <ColumnAlternatingYouTube
                    key={index}
                    headingLevel={2}
                    title={videos.title}
                    subtitle={videos.subtitle}
                    openingpara={videos.openingpara}
                    description={videos.description}
                    vidUrl={videos.videourl}
                    placement={videos.placement}
                    titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                    link={videos.link}
                    linktext={videos.linktext}
                    ctalink={videos.ctalink}
                    ctatext={videos.ctatext}
                    icon="right"
                  />
                ))}
              </div>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}

      {post.frontmatter.award && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <ColumnAlternatingButton
                textCol="8"
                imageCol="4"
                headingLevel={2}
                title={post.frontmatter.award.title}
                description={post.frontmatter.award.description}
                image={post.frontmatter.award.image.childImageSharp.gatsbyImageData}
                alt={post.frontmatter.award.alttext}
                placement={post.frontmatter.award.placement}
                titleClass="font-alt font-w-400 letter-spacing-1 mb-4 title-xs-medium title-medium"
                link={post.frontmatter.award.link}
                linktext={post.frontmatter.award.linktext}
              />
            </MDBContainer>
          </FadeInWhenVisible> 
        </section>
      )}

      {post.frontmatter.performance && (
       <section className="bg-blue-gradient">
        <FadeInWhenVisible>
          <MDBContainer>
            <PerformanceFocus performance={post.frontmatter.performance} />
          </MDBContainer>
        </FadeInWhenVisible>
       </section>
      )}

      {post.frontmatter.section && post.frontmatter.section[0] && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol lg="12">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-4">
                    {post.frontmatter.section[0].title}
                  </h2>
                </MDBCol>
                <MDBCol lg="12">
                  <div 
                    className="pb-4 text-medium divlink"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.section[0].description) }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                {post.frontmatter.section[0].feature.map((platforms, index) => (
                  <TechPartnerPlatforms
                    key={index}
                    title={platforms.title}
                    subtitle={platforms.subtitle}
                    description={platforms.description}
                    image={platforms.image.childImageSharp.gatsbyImageData}
                    linktext={platforms.linktext}
                    link={platforms.link}
                    datasheetlinktext={platforms.datasheetlinktext}
                    datasheetlink={ platforms.document && platforms.document.publicURL }
                    alt={platforms.alttext}
                  />
                ))}
              </MDBRow>
              {post.frontmatter.section[0].link && (
                <MDBRow>
                  <MDBCol lg="12">
                    <div>
                      <CustomLink
                        link="/platforms/"
                        className="nav-link btn btn-mdb-color btn-sm-block mr-0 mt-xl-3 btn-sm-block"
                        style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                        {post.frontmatter.section[0].linktext}
                      </CustomLink>
                    </div>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}


      {post.frontmatter.whywork && (
        <section className="bg-white">
          <FadeInWhenVisible>
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">
                {post.frontmatter.whywork.title}
              </h3>
              <div
                className="mb-5 font-w-400 text-medium"
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.whywork.subtitle) }}
              />
                <ColumnAlternatingButton
                  textCol="5"
                  imageCol="7"
                  headingLevel={2}
                  title={post.frontmatter.whywork.details.title}
                  subtitle={post.frontmatter.whywork.details.subtitle}
                  description={post.frontmatter.whywork.details.description}
                  image={post.frontmatter.whywork.details.image.childImageSharp.gatsbyImageData}
                  alt={post.frontmatter.whywork.details.alttext}
                  imageClass="rounded drop-shadow"
                  placement={post.frontmatter.whywork.details.placement}
                  titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                  link={post.frontmatter.whywork.details.link}
                  linktext={post.frontmatter.whywork.details.linktext}
                />
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}


      {post.frontmatter.section && post.frontmatter.section[1] && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol lg="12">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-3">
                    {post.frontmatter.section[1].title}
                  </h2>
                </MDBCol>
                <MDBCol lg="12">
                  <div 
                    className="pb-4 text-medium divlink"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.section[1].description) }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                {post.frontmatter.section[1].feature.map((servers, index) => (
                  <TechPartnerPlatforms
                    key={index}
                    title={servers.title}
                    subtitle={servers.subtitle}
                    image={servers.image.childImageSharp.gatsbyImageData}
                    linktext={servers.linktext}
                    link={servers.link}
                    datasheetlinktext={servers.datasheetlinktext}
                    datasheetlink={ servers.document && servers.document.publicURL }
                    alt={servers.alttext}
                  />
                ))}
              </MDBRow>
              {post.frontmatter.section[1].link && (
                <MDBRow>
                  <MDBCol lg="12">
                    <div>
                      <CustomLink
                        link="/platforms/"
                        className="nav-link btn btn-mdb-color mr-0 mt-xl-3 btn-sm-block"
                        style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                        {post.frontmatter.section[1].linktext}
                      </CustomLink>
                    </div>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}



      {post.frontmatter.section && post.frontmatter.section[2] && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol lg="12">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-3">
                    {post.frontmatter.section[2].title}
                  </h2>
                </MDBCol>
                <MDBCol lg="12">
                  <div 
                    className="pb-4 text-medium divlink"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.section[2].description) }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                {post.frontmatter.section[2].feature.map((servers, index) => (
                  <TechPartnerPlatforms
                    key={index}
                    title={servers.title}
                    subtitle={servers.subtitle}
                    image={servers.image.childImageSharp.gatsbyImageData}
                    linktext={servers.linktext}
                    link={servers.link}
                    datasheetlinktext={servers.datasheetlinktext}
                    datasheetlink={ servers.document && servers.document.publicURL }
                    alt={servers.alttext}
                  />
                ))}
              </MDBRow>
              {post.frontmatter.section[2].link && (
                <MDBRow>
                  <MDBCol lg="12">
                    <div>
                      <CustomLink
                        link="/platforms/"
                        className="nav-link btn btn-mdb-color mr-0 mt-xl-3 btn-sm-block"
                        style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                        {post.frontmatter.section[2].linktext}
                      </CustomLink>
                    </div>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}


      {post.frontmatter.section && post.frontmatter.section[3] && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol lg="12">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-3">
                    {post.frontmatter.section[3].title}
                  </h2>
                </MDBCol>
                <MDBCol lg="12">
                  <div 
                    className="pb-4 text-medium divlink"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.section[3].description) }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                {post.frontmatter.section[3].feature.map((servers, index) => (
                  <TechPartnerPlatforms
                    key={index}
                    title={servers.title}
                    subtitle={servers.subtitle}
                    image={servers.image.childImageSharp.gatsbyImageData}
                    linktext={servers.linktext}
                    link={servers.link}
                    datasheetlinktext={servers.datasheetlinktext}
                    datasheetlink={ servers.document && servers.document.publicURL }
                    alt={servers.alttext}
                  />
                ))}
              </MDBRow>
              {post.frontmatter.section[3].link && (
                <MDBRow>
                  <MDBCol lg="12">
                    <div>
                      <CustomLink
                        link="/platforms/"
                        className="nav-link btn btn-mdb-color mr-0 mt-xl-3 btn-sm-block"
                        style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                        {post.frontmatter.section[3].linktext}
                      </CustomLink>
                    </div>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}


      {post.frontmatter.asset && (
        <>
          {post.frontmatter.asset.map((assets, index) => (
            <section className={ !post.frontmatter.section || !post.frontmatter.section[0] ? 'bg-white-grey-gradient' : index % 2 ? 'bg-white-grey-gradient' : 'bg-white' } key={index} >
              <FadeInWhenVisible>
                <MDBContainer>
                  <ColumnAlternatingButton
                    textCol="8"
                    imageCol="4"
                    headingLevel={2}
                    title={assets.title}
                    subtitle={assets.subtitle}
                    description={assets.description}
                    image={assets.image.childImageSharp.gatsbyImageData}
                    alt={assets.alttext}
                    placement={assets.placement}
                    titleClass="font-alt font-w-400 letter-spacing-1 mb-4 title-xs-medium title-medium"
                    link={assets.link}
                    linktext={assets.linktext}
                    icon="right"
                  />
                </MDBContainer>
              </FadeInWhenVisible>
            </section>
          ))}
        </>
      )}


      {post.frontmatter.team && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium pb-5">
                {post.frontmatter.team.title}
              </h2>
              {post.frontmatter.team.featurecard && (
                <MDBRow>
                  {post.frontmatter.team.featurecard.map((cards, index) => (
                    <CardIconsLink
                      key={index}
                      title={cards.title}
                      link={cards.link}
                      image={cards.image.childImageSharp.gatsbyImageData}
                      alt={cards.alttext}
                    />
                  ))}
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}


      {post.frontmatter.model && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <ColumnAlternatingButton
                textCol="5"
                imageCol="7"
                headingLevel={2}
                title={post.frontmatter.model.title}
                description={post.frontmatter.model.description}
                image={post.frontmatter.model.image.childImageSharp.gatsbyImageData}
                alt={post.frontmatter.model.alttext}
                placement={post.frontmatter.model.placement}
                titleClass="font-alt font-w-400 letter-spacing-1 mb-4 title-xs-medium title-medium"
                link={post.frontmatter.model.link}
                linktext={post.frontmatter.model.linktext}
                icon="right"
              />
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}



      {post.frontmatter.helpfulresources && (
        <section className="bg-gray-light">
          <FadeInWhenVisible>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.helpfulresources.title}
              </h2>
              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                    <Card
                      key={index}
                      collg="4"
                      colmd="6"
                      height="7.5rem"
                      title={helpfulres.title}
                      subtitle={helpfulres.subtitle}
                      description={helpfulres.description}
                      image={helpfulres.image.childImageSharp.gatsbyImageData}
                      alt={helpfulres.alttext}
                      placement={helpfulres.placement}
                      link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                      descriptionClass="text-card-small"
                    />
                  ))}
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}


      <Cta
        ctatitle={post.frontmatter.cta.title}
        ctadescription={post.frontmatter.cta.description}
        ctalink={post.frontmatter.cta.link}
        ctalinktext={post.frontmatter.cta.linktext}
      />
    </Layout>
  )
}
export default TechnologyPartnersTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "technology-partners" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug 
        logo {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        logoalttext        
        performance {
          title
          subtitle
          items {
            title
            subtitle
            link
            linktext
          }
        }
        section {
          title
          description
          link
          linktext
          platformlink
          platformlinktext
          document {
            publicURL
          }
          feature {
            title
            subtitle
            alttext
            linktext
            link
            datasheetlinktext
            datasheetlink
            document {
              publicURL
            }
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }
        whywork {
          title
          subtitle
          details {
            title
            subtitle
            description
            placement
            linktext
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }        
        award {
          title
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
        }
        asset {
          title
          subtitle
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
        video {
          title
          openingpara
          description
          placement
          videourl
          link
          linktext
          ctalink
          ctatext
        }
        model {
          title
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
        }
        team {
          title
          featurecard {
            title
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 70, height: 70, quality: 90) 
              }
            }
          }
        }
        podcasts {
          title
          description 
          podcast {
            title
            link
            linktext 
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            linktext
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            document {
              publicURL
            }           
          }
        }
      }
      html
    }
  }
`