import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import FadeInWhenVisible from './fadeInWhenVisible'
import CustomLink from './CustomLink'

class PerformanceFocus extends React.Component {
  render() {
    return (
      <MDBRow>
        <MDBCol lg="12">
          <FadeInWhenVisible>
            <MDBRow>
              <MDBCol lg="12" md="12">
                <MDBRow>
                  <MDBCol md="12">
                    <h2 className="font-w-700 font-alt text-white title-xs-large title-large mb-xl-2 pb-4" 
                    dangerouslySetInnerHTML={{ __html: this.props.performance.title, }} />
                  </MDBCol>

                  <MDBCol md="12">
                    <div className="font-w-400 text-white font-alt letter-spacing-1 title-small" 
                    dangerouslySetInnerHTML={{ __html: this.props.performance.subtitle, }} />
                  </MDBCol>

                  {this.props.performance.items.map((item, index) => (
                    <MDBCol lg="3" md="6" className="mt-5" key={index}>
                      <div className="mt-5 pt-lg-0">
                        <div style={{ minHeight: '260px', maxHeight: '300px' }}>
                          <p className="font-alt text-white font-w-700 title-extra-large py-4 item">{item.title}</p>
                          <p className="text-medium text-white">{item.subtitle}</p>
                        </div>
                        <CustomLink link={item.link} className="text-small effect text-white">{item.linktext}</CustomLink>
                      </div>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </FadeInWhenVisible>
        </MDBCol>
      </MDBRow>
    )
  }
}
export default PerformanceFocus