import React from 'react'
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import CustomLink from './CustomLink'

class techPartnerPlatforms extends React.Component {
  render() {
    return (
      <MDBCol lg="3" md="6" className="pb-4 d-flex pr-md-0">
        <MDBCard className="flex-fill">
          <GatsbyImage
            image={this.props.image}
            alt={this.props.alt}
            className="m-3"
          />
          <MDBCardBody>
            <div>
              <h3 className="font-alt font-w-400 letter-spacing-1 text-medium pb-2 text-center">
                {this.props.title}
              </h3>
              {this.props.subtitle && (
                <p className="font-w-700 title-extra-small text-black text-center mb-2">
                  {this.props.subtitle}
                </p>
              )}
            </div>

            <hr />

            <div className="font-w-400 text-medium mb-2">
              <MDBRow className="pt-2">
                <MDBCol>
                  <div className="text-center">
                    {this.props.link && (
                      <CustomLink link={this.props.link} className="effect">
                        {this.props.linktext}
                      </CustomLink>
                    )}
                  </div>

                  {this.props.datasheetlink && (
                    <div className="text-center">
                      <a href={this.props.datasheetlink} className="effect" target="_blank">
                        {this.props.datasheetlinktext}
                      </a>
                    </div>
                  )}
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}
export default techPartnerPlatforms