import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import CookiesParseHtml from "./cookiesParseHtml"
import CustomLink from './CustomLink'
import Showdown from 'showdown'

class ColumnAlternatingYouTube extends React.Component {
  render() {
    const Heading = (this.props.headingLevel && Number.isInteger(this.props.headingLevel)) ? `h${this.props.headingLevel}` : 'h3'
    const SubHeading = (this.props.headingLevel && Number.isInteger(this.props.headingLevel)) ? `h${this.props.headingLevel+1}` : 'h4'
    const converter = new Showdown.Converter()
    return (
      <MDBRow className={`about-item align-items-top ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
        {(this.props.vidUrl != null && this.props.vidUrl != "") && (
          <MDBCol lg="7">
            <div className="embed-responsive embed-responsive-16by9">
              <CookiesParseHtml html={`<div><iframe className="embed-responsive-item rounded border" src="${this.props.vidUrl}?rel=0&modestbranding=1"></iframe></div>`} />
            </div>
          </MDBCol>
        )}

        <MDBCol lg={(this.props.vidUrl != null && this.props.vidUrl.length >= 1) ? '5' : '12'} className="mt-4 mt-lg-0 mb-4 mb-lg-0" >
          <Heading className={ this.props.titleClass != null ? this.props.titleClass : 'font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium' } >
            {this.props.title}
          </Heading>

          {this.props.subtitle != null && (
            <SubHeading className="font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium">
              {this.props.subtitle}
            </SubHeading>
          )}

          <div
            className="mt-3 mb-3 font-w-400 text-medium" style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.openingpara)}}
          />
        </MDBCol>

        {this.props.description != '' && this.props.description != null && (
          <MDBCol lg="12">
            <div
              className="mt-md-3 mb-3 font-w-400 text-medium richtext" style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.description)}}
            />
          </MDBCol>
        )}

        <MDBCol lg="12">
          {this.props.link && 
            <CustomLink link={this.props.link} className="nav-link btn btn-mdb-color mt-4 noeffect">
              <FontAwesomeIcon icon={this.props.icon == "right" ? faChevronRight : faDownload} className="mr-2" />
              {this.props.linktext}
            </CustomLink>
          }

          {(this.props.ctalink && this.props.ctatext !== " ") && 
            <CustomLink link={this.props.ctalink} className="nav-link btn btn-mdb-color mt-4 noeffect">
              <FontAwesomeIcon icon={this.props.icon == 'right' ? faChevronRight : faDownload} className="mr-2" />
              {this.props.ctatext}
            </CustomLink>
          }
        </MDBCol>
      </MDBRow>
    )
  }
}
export default ColumnAlternatingYouTube